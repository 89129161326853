//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    // --------
    // ENTERING
    // --------

    beforeEnter: function (el) {
      const formElements = [].slice.call(
        el.querySelectorAll('[data-animation="form-element"]')
      )
      this.$gsap.set(formElements, {
        opacity: 0,
        y: 25,
      })
    },
    // the done callback is optional when
    // used in combination with CSS
    enter: function (el, done) {
      const formElements = [].slice.call(
        el.querySelectorAll('[data-animation="form-element"]')
      )
      this.$gsap.to(formElements, {
        opacity: 1,
        duration: 0.9,
        stagger: 0.075,
        ease: 'power3.out',
        y: 0,
        onComplete: done,
      })
    },

    // --------
    // LEAVING
    // --------
    // the done callback is optional when
    // used in combination with CSS
    leave: function (el, done) {
      const formElements = [].slice.call(
        el.querySelectorAll('[data-animation="form-element"]')
      )
      this.$gsap.to(formElements, {
        opacity: 0,
        duration: 0.4,
        stagger: 0.025,
        ease: 'power2.inOut',
        y: -25,
        onComplete: done,
      })
    },
  },
}
