import { render, staticRenderFns } from "./FindDepartmentForm.vue?vue&type=template&id=6134d6a8&scoped=true&"
import script from "./FindDepartmentForm.vue?vue&type=script&lang=js&"
export * from "./FindDepartmentForm.vue?vue&type=script&lang=js&"
import style0 from "./FindDepartmentForm.vue?vue&type=style&index=0&id=6134d6a8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6134d6a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FindDepartmentItem: require('/workspace/components/FindDepartmentItem.vue').default})
