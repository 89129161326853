//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    beforeEnter: function (el) {
      const element = el.querySelector('[data-animation-fade-in]')
      this.$gsap.set(element, {
        x: '100%',
      })
    },
    // the done callback is optional when
    // used in combination with CSS
    enter: function (el, done) {
      const element = el.querySelector('[data-animation-fade-in]')
      this.$gsap.to(element, {
        x: 0,
        duration: 1,
        delay: 0.5,
        ease: 'power3.out',
        onComplete: done,
      })
    },

    leave: function (el, done) {
      const element = el.querySelector('[data-animation-fade-in]')
      this.$gsap.set(element, {
        duration: 1,
        x: '100%',
        ease: 'power2.inOut',
        onComplete: done,
      })
    },
  },
}
