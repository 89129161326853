//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
const STATES = {
  IDLE: 'idle',
  WORKING: 'working',
  SUCCESS: 'success',
  ERROR: 'error',
}
export default {
  data() {
    return {
      email: null,
      forgotPasswordState: STATES.IDLE,
    }
  },
  computed: {
    buttonOptions() {
      return {
        showSpinner: this.forgotPasswordState === STATES.WORKING,
        spinnerText: 'Arbejder...',
        showSuccess: this.forgotPasswordState === STATES.SUCCESS,
        successText: 'Success!',
        showError: this.forgotPasswordState === STATES.ERROR,
        errorText: 'Noget gik galt - prøv igen',
      }
    },
    showSuccessText() {
      return this.forgotPasswordState === STATES.SUCCESS
    },
  },
  methods: {
    ...mapMutations({
      setModal: 'modal/setModal',
      setToast: 'toast/setToast',
    }),
    handleResetPasswordError() {
      this.forgotPasswordState = STATES.ERROR
      this.setToast({
        type: 'LoginToast',
        data: {
          title: 'Kunne ikke ønske ny adgangskode',
          subtitle: this.$t('general.toast_error'),
        },
      })
    },
    async handleFormSubmit(event) {
      this.forgotPassword()
      return
    },
    async forgotPassword() {
      this.forgotPasswordState = STATES.WORKING
      const body = {
        email: this.email,
      }

      let forgotPasswordResponse = null

      try {
        forgotPasswordResponse = await this.$api.forgotPassword(body)
      } catch (error) {
        console.log('Error when requesting new password:')
        console.log(error)
        this.$bugsnag.notify(new Error(error))
        this.handleResetPasswordError()
      }

      if (forgotPasswordResponse === null) {
        this.handleResetPasswordError()
        return
      }

      this.forgotPasswordState = STATES.SUCCESS
    },
  },
}
