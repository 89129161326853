//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import ForgotPasswordForm from './ForgotPasswordForm.vue'
const STATES = {
  IDLE: 'idle',
  WORKING: 'working',
  SUCCESS: 'success',
  ERROR: 'error',
}
export default {
  data() {
    return {
      email: null,
      password: null,
      rememberMe: false,
      loginState: STATES.IDLE,
    }
  },
  computed: {
    buttonOptions() {
      return {
        showSpinner: this.loginState === STATES.WORKING,
        spinnerText: 'Logger ind...',
        showSuccess: this.loginState === STATES.SUCCESS,
        successText: 'Logget ind',
        showError: this.loginState === STATES.ERROR,
        errorText: 'Kunne ikke logge ind',
      }
    },
  },
  methods: {
    ...mapMutations({
      setModal: 'modal/setModal',
      setToast: 'toast/setToast',
    }),
    handleLoginError() {
      this.loginState = STATES.ERROR
      this.setToast({
        type: 'LoginToast',
        data: {
          title: 'Kunne ikke logge ind',
          subtitle: 'Kontrollér din email eller dit password og prøv igen.',
        },
      })
      // Clear store just in case we got a partial log-in
      this.$store.dispatch('user/logOut')
      this.$store.dispatch('auth/clearCookies')

      this.$store.commit('auth/setToken', null)
      this.$store.commit('setDepartment', null)
      this.$store.commit('setTheme', null)
      this.$store.commit('setDepartmentExtData', null)
    },
    handleForgotPassword() {
      this.$emit('onShowForgotPassword')
    },
    async handleFormSubmit(event) {
      this.login()
      return
    },
    async login() {
      this.loginState = STATES.WORKING
      const body = {
        email: this.email,
        password: this.password,
        remember_me: this.rememberMe,
      }
      let loginResponse = null
      try {
        loginResponse = await this.$api.login(body)
      } catch (error) {
        console.log('Error when logging in:')
        console.log(error)
        this.handleLoginError()
      }
      if (!loginResponse || !loginResponse?.token) {
        this.handleLoginError()
        return
      }
      const token = loginResponse?.token
      if (this.rememberMe) {
        // Set token in cookie for later retrieval
        await this.$store.commit('auth/setTokenCookie', token)
      }
      await this.$store.commit('auth/setToken', token)
      const tenant = await this.$api.tenant()
      if (!tenant) {
        console.error('Something went wrong when fetching tenant...')
        this.handleLoginError()
        return
      }

      const userIsExternal =
        tenant?.data?.external_person !== undefined ?? false

      const userDepartment = userIsExternal
        ? {
            ...tenant?.data?.department,
            board_module: true,
            tenant_module: false,
          }
        : tenant?.data?.department
      const departmentSlug = userDepartment?.slug
      if (departmentSlug) {
        this.loginState = STATES.SUCCESS
        this.$store.commit('setTheme', userDepartment?.theme)
        this.$router.push(departmentSlug)
        this.$store.commit('setDepartment', userDepartment)

        // Fetch ext. data
        if (!userIsExternal) {
          const extData = await this.$api.departmentExtDataById(
            tenant?.data?.department?.ext_department_id
          )
          this.$store.commit('setDepartmentExtData', extData)
        }
      } else {
        this.handleLoginError()
        return
      }
    },
  },
  components: { ForgotPasswordForm },
}
