//
//
//
//
//
//
//
//
//
//
//

export default {
  props: { department: Object },
  methods: {
    goToDepartment() {
      // Set department as current in store.
      this.$store.commit('setDepartment', this.department)
      this.$router.push(`/${this.department?.slug}`)
    },
  },
}
