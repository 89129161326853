//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      query: [],
      departments: null,
    }
  },
  methods: {
    async searchDepartment(zip) {
      if (zip === '') {
        this.departments = null
        return
      }
      await this.findDepartmentByZip(zip)
    },
    async findDepartmentByZip(zip) {
      try {
        const departments = await this.$api.departmentsByZip(zip)
        this.departments = departments.data
      } catch (error) {
        console.log('Error when searching for deparments by zip:')
        console.log(error)
      }
    },
    focusInput() {
      this.$refs.zipInput.scrollIntoView({ behavior: 'smooth' })
    },
    handleFormSubmit(event) {
      event.preventDefault()
      return
    },
  },
  watch: {
    async query(newQuery) {
      this.$nextTick(async () => await this.searchDepartment(newQuery))
    },
  },
}
