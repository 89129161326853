//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showSpinner: { type: Boolean, default: false },
    spinnerText: { type: String, default: 'Gemmer...' },

    showSuccess: { type: Boolean, default: false },
    successText: { type: String, default: 'Success' },

    showError: { type: Boolean, default: false },
    errorText: { type: String, default: 'Noget gik galt' },

    buttonType: { type: String, default: 'button' },
  },
  computed: {
    showFeedback() {
      return this.showSpinner || this.showSuccess || this.showError
    },
    feedbackText() {
      if (this.showSpinner) return this.spinnerText
      if (this.showSuccess) return this.successText
      if (this.showError) return this.errorText
    },
    feedbackIcon() {
      return this.showSuccess ? 'checkmark' : 'Close'
    },
    feedbackIconClass() {
      if (this.showError) return 'text-[#EB5757]'
      if (this.showSuccess) return 'text-[#A5ED7B]'
    },
  },
}
