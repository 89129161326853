//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Login',
  layout: 'landing',
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    mitIdAuthCookie() {
      return this.$cookies.get('mitID-auth')
    },
    alreadyAuthedWithMitId() {
      return this.mitIdAuthCookie === true
    },
    mitIdScope() {
      // If a user has already been authed once, we don't need their SSN when logging in with MitID.
      if (this.alreadyAuthedWithMitId) return 'openid mitid'
      return 'openid mitid ssn'
    },
    hostname() {
      return this.$config.hostname
    },
    mitIdRedirectUrl() {
      return `${this.hostname}/callback`
    },
    mitIdLink() {
      const brokerUrl = process.env.NUXT_ENV_MIT_ID_BROKER_URL
      return `${brokerUrl}&redirect_uri=${this.mitIdRedirectUrl}&response_type=code&scope=${this.mitIdScope}`
    },
    activePage() {
      return this.$route?.query?.page || null
    },
    showProviderSelection() {
      return this.activePage === null
    },
    showLogin() {
      return this.activePage === 'login'
    },
    showForgotPassword() {
      return this.activePage === 'forgot-password'
    },
    showFindDepartment() {
      return this.activePage === 'find-department'
    },
  },
  methods: {
    changePage(pageName) {
      if (!pageName) {
        this.$router.push({ query: null })
        return
      }
      this.$router.push({ query: { page: pageName } })
    },
    goBack() {
      switch (this.activePage) {
        case 'forgot-password':
          this.changePage('login')
          break
        default:
          this.changePage('')
          break
      }
    },
  },
  mounted() {
    this.loaded = true

    // Makes sure user is logged out
    this.$store.dispatch('user/logOut')
    this.$store.dispatch('auth/clearCookies')

    this.$store.commit('auth/setToken', null)

    this.$store.commit('setDepartment', null)
    this.$store.commit('setTheme', null)
  },
}
